import styled from 'styled-components'

import { Section as GSSection } from 'components/general-styles'
import { theme } from 'styles/theme'

const { colors, media, space, fontSizes, fontWeights, radii } = theme

export const Section = styled(GSSection)`
  padding-top: 0;

  @media (${media.tablet}) {
    padding-top: ${space['spacing-xl']};
    padding-bottom: ${space['spacing-xl']};
  }

  @media (${media.desktop}) {
    padding-bottom: 7.5rem;
  }
`

export const Content = styled.div`
  @media (${media.tablet}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: ${space['spacing-xxl']};
  }
`

export const ImageWrapper = styled.div`
  margin-left: -${space['spacing-md']};
  margin-right: -${space['spacing-md']};
  margin-bottom: ${space['spacing-xxl']};

  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
    min-width: calc(50% - ${space['spacing-sm']});
    margin: 0;
    border-radius: ${radii['corner-radius-sm']};
    overflow: hidden;
  }
`

export const Infos = styled.div`
  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
  }

  p {
    margin-bottom: ${space['spacing-md']};
  }

  h3 {
    margin-bottom: ${space['spacing-sm']};
  }

  @media (${media.desktop}) {
    h3 {
      margin-bottom: ${space['spacing-sm']};
      color: ${colors['neutral-text-default']};
      font-size: ${fontSizes['font-size-heading-3']};
      font-weight: ${fontWeights['font-weight-medium']};
    }

    p {
      margin-bottom: ${space['spacing-xl']};
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${space['spacing-xs']};

  @media (${media.desktop}) {
    gap: ${space['spacing-sm']};
  }
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${space['spacing-md']};
`

export const ListItemIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space['spacing-xl']};
  height: ${space['spacing-xl']};
  border-radius: ${radii['corner-radius-circular']};
  background-color: ${colors['neutral-background-default']};
`

export const ListItemText = styled.span`
  color: ${colors['neutral-text-default']};
  font-size: ${fontSizes['font-size-body-1']};

  @media (${media.desktop}) {
    font-size: ${fontSizes['font-size-heading-5']};
    font-weight: ${fontWeights['font-weight-medium']};
  }
`
